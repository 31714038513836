import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './scss/app.scss'
import axios from 'axios';

Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});

const app = createApp(App).use(router);

axios.defaults.baseURL = process.env.VUE_APP_INTRANET_API_URL; // Replace with your API URL

if (localStorage.token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.token }`;
}

axios.defaults.headers.common['Content-Type'] = 'application/json';

// Mount Axios and oAuth
app.config.globalProperties.axios = axios;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

app.mount('#app');