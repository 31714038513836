<template>
  <div>
    <div class="mb-4">
      <h4 style="font-weight: 800">Login</h4>
    </div>

    <div class="mb-3">
      <label>Email</label>
      <input type="email" class="custom-input" v-model="credentials.email">
    </div>

    <div class="mb-4">
      <label>Password</label>
      <input type="password" class="custom-input" v-model="credentials.password">
    </div>

    <div>
      <button v-if="status === 0" @click="authenticate"
              class="btn btn-sm blue-grey lighten-4 grey-text text-darken-4 font-700">
        <i class="fa-solid fa-arrow-right-to-bracket me-1"></i> Enviar
      </button>

      <button v-else-if="status === 1" class="btn btn-sm blue-grey lighten-4 grey-text text-darken-4 font-700 disabled">
        <i class="fa-solid fa-spinner fa-spin me-1"></i> Entrar
      </button>

      <button v-else-if="status === 2" @click="authenticate"
              class="btn btn-sm green lighten-4 green-text text-darken-4 font-700">
        <i class="fa-solid fa-arrow-right-to-bracket me-1"></i> Entrar
      </button>

      <button v-else-if="status === 3" @click="authenticate"
              class="btn btn-sm red lighten-4 red-text text-darken-4 font-700">
        <i class="fa-solid fa-arrow-right-to-bracket me-1"></i> Error
      </button>

      <button class="btn btn-sm transparent font-700">
        Olvidé mi contraseña
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginComponent',

  data() {
    return {
      credentials: {
        email: null,
        password: null
      },
      status: 0
    }
  },

  methods: {
    authenticate() {
      this.status = 1;

      this.axios.post('authenticate', {
        email: this.credentials.email,
        password: this.credentials.password
      }).then(resp => {
        localStorage.setItem('token', resp.data);
        this.axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.token }`

        this.$emit('auth');
        this.status = 2;
      }).catch(() => {
        this.$emit('logout')
        localStorage.removeItem("token");
        this.status = 3;
      });
    }
  },

}
</script>